import React, { Component } from "react";
import { Player, ControlBar } from "video-react";
import screenfull from "screenfull";
import Media from "react-media";
import DeviceOrientation, { Orientation } from "react-screen-orientation";
import "./video.css";

//Lien des vidéos interactive
const sources = {
  N0V0: "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4",

  N1V1: "http://videointeractive.henri8.com/VIDEOSIVI/N1V1.mp4",
  N1V2: "http://videointeractive.henri8.com/VIDEOSIVI/N1V2.mp4",

  N2V1: "http://videointeractive.henri8.com/VIDEOSIVI/N2V1.mp4",
  N2V2: "http://videointeractive.henri8.com/VIDEOSIVI/N2V2.mp4",

  N3V1: "http://videointeractive.henri8.com/VIDEOSIVI/N3V1.mp4",
  N3V2: "http://videointeractive.henri8.com/VIDEOSIVI/N3V2.mp4",
  N3V3: "http://videointeractive.henri8.com/VIDEOSIVI/N3V3.mp4",

  N5V1: "http://videointeractive.henri8.com/VIDEOSIVI/N5V1.mp4",
  N5V2: "http://videointeractive.henri8.com/VIDEOSIVI/N5V2.mp4",
  N5V3: "http://videointeractive.henri8.com/VIDEOSIVI/N5V3.mp4",

  FINALGOOD: "http://videointeractive.henri8.com/VIDEOSIVI/FINAL-GOOD.mp4",
  FINALBAD: "http://videointeractive.henri8.com/VIDEOSIVI/FINAL-BAD.mp4",
};
class Video extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      source: sources.N0V0,
      video: "",
      currentSrc: "",
      isCkeck1: false,
      isCkeck2: false,
      isCkeck3: false,
      isCkeck4: false,
      isCkeck5: false,
      mymodal: 0,
      mymodal2: 0,
      mymodal3: 1,

      numberOfGuests: 2,
      play: 1,
      statofPlayer: false,
      active: false,
      hover: false,
      hover2: false,
      hoverbtnHC: false,
      hoverbtnHSC: false,
      opacity: 0.5,
      scale: 1,
      ended: false,
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.changeCurrentTime = this.changeCurrentTime.bind(this);
    this.seek = this.seek.bind(this);
    this.changePlaybackRateRate = this.changePlaybackRateRate.bind(this);
    this.changeVolume = this.changeVolume.bind(this);
    this.setMuted = this.setMuted.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNiveau1 = this.handleNiveau1.bind(this);
    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.handleJumbeAndDisplay = this.handleJumbeAndDisplay.bind(this);
    this.handleFinalQuestion = this.handleFinalQuestion.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
    this.handleClose3 = this.handleClose3.bind(this);
    this.handleShowHC = this.handleShowHC.bind(this);
    this.handleShowHSC = this.handleShowHSC.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.toggleClass = this.toggleClass.bind(this);

    this.hoverOn = this.hoverOn.bind(this);
    this.hoverOff = this.hoverOff.bind(this);

    this.hoverOn2 = this.hoverOn2.bind(this);
    this.hoverOff2 = this.hoverOff2.bind(this);
    this.onScale = this.onScale.bind(this);

    this.hoverOnHC = this.hoverOnHC.bind(this);
    this.hoverOffHC = this.hoverOffHC.bind(this);

    this.hoverOnHSC = this.hoverOnHSC.bind(this);
    this.hoverOffHSC = this.hoverOffHSC.bind(this);
  }

  componentDidMount() {
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  setMuted(muted) {
    return () => {
      this.player.muted = muted;
    };
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
      video: this.player.video.video.currentTime,
      currentSrc: this.player.video.video.currentSrc,
      statofPlayer: this.player.video.video.paused,
      ended: this.player.video.video.ended,
    });

    // console.log("Mon Os :" + this.getOs());
    /*
    if (this.getOs() === "Mac") {
      console.log("je suis un MacOs");
    }*/

    // console.log("statue du player : " + JSON.stringify(state));
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  load() {
    this.player.load();
  }

  changeCurrentTime(seconds) {
    return () => {
      const { player } = this.player.getState();
      this.player.seek(player.currentTime + seconds);
    };
  }

  seek(seconds) {
    return () => {
      this.player.seek(seconds);
    };
  }

  changePlaybackRateRate(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.playbackRate = player.playbackRate + steps;
    };
  }

  changeVolume(steps) {
    return () => {
      const { player } = this.player.getState();
      this.player.volume = player.volume + steps;
    };
  }

  changeSource(name) {
    return () => {
      setTimeout(
        function () {
          this.setState({
            source: sources[name],
          });
          this.player.load();
        }.bind(this),
        1000
      );
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  handleNiveau1() {
    if (
      this.state.isCkeck1 === true &&
      this.state.isCkeck2 === true &&
      this.state.isCkeck3 === false &&
      this.state.isCkeck4 === true &&
      this.state.isCkeck5 === false
    ) {
      this.setState({
        source: sources["N2V1"],
      });
      this.player.load();
    } else {
      this.setState({
        source: sources["N2V2"],
      });
      this.player.load();
      //console.log("autre choix");
    }
  }
  handleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.request();
    } else {
      console.log("yes");
    }
  }
  handleJumbeAndDisplay() {
    //this.changeSource("N1V1");
    this.setState({
      source: sources["N1V1"],
    });
    this.player.load();
    this.player.seek(29.999999);
    //console.log("oui");
  }
  handleFinalQuestion() {
    this.setState({
      source: sources["N4V2"],
    });
    this.player.load();
    this.player.seek(25.999999);
  }
  handleClose() {
    this.setState({ mymodal: 0 });
    if (this.state.ended === false) {
      this.player.play();
    }
  }
  handleClose2() {
    this.setState({ mymodal2: 0 });
    if (this.state.ended === false) {
      this.player.play();
    }
  }
  handleClose3() {
    this.setState({ mymodal3: 0, source: sources["N1V1"] });
    this.player.load();
    this.player.pause();
  }
  handleShowHC() {
    this.setState({ mymodal: 1 });
    this.player.pause();
  }
  handleShowHSC() {
    this.setState({ mymodal2: 1 });
    this.player.pause();
  }
  handlePlay() {
    this.player.play();
    if (screenfull.isEnabled) {
      screenfull.request();
    }
  }
  toggleClass() {
    this.setState({
      active: true,
    });
    setTimeout(
      function () {
        this.setState({
          active: true,
        });
      }.bind(this),
      1000
    );
  }

  hoverOn() {
    this.setState({ hover: true });
  }
  hoverOff() {
    this.setState({ hover: false });
  }
  hoverOn2() {
    this.setState({ hover2: true });
  }
  hoverOff2() {
    this.setState({ hover2: false });
  }
  hoverOnHC() {
    this.setState({ hoverbtnHC: true });
  }
  hoverOffHC() {
    this.setState({ hoverbtnHC: false });
  }

  hoverOnHSC() {
    this.setState({ hoverbtnHSC: true });
  }
  hoverOffHSC() {
    this.setState({ hoverbtnHSC: false });
  }

  onScale() {
    this.setState({
      scale: this.state.scale > 1 ? 1 : 1.3,
    });
  }

  getOs = () => {
    const os = ["Windows", "Linux", "Mac"]; // add your OS values
    return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
  };
  render() {
    //console.log("Mon Os" + this.getOs());
    /* styling */
    const ImageFond = {
      position: "fixed",
      top: "1%",
      left: "5%",
      height: "95%",
      width: "90%",
      opacity: 1,
    };

    const margBott = {
      marginBottom: 30,
    };

    const SpanLef = {
      marginLeft: 30,
    };

    var MySpan = "";
    //console.log(this.state.active);
    if (this.state.active === true) {
      MySpan = (
        <div>
          {/*
           <span
            style={{
              color: "green",
              marginLeft: "5%",
              top: "0px",
              position: "relative",
              float: "right"
            }}
          >
            Valider
          </span>
          */}
        </div>
      );
    }
    //modal
    var myModal = "";

    if (this.state.mymodal === 1) {
      myModal = (
        <div
          onDoubleClick={this.handleClose}
          style={{
            position: "fixed",
            zIndex: "2147483647",
            display: "block",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            className="jumbotron"
            style={{
              position: "fixed",
              zIndex: "2147483647",
              width: "80%",
              height: "80%",
              top: "10%",
              left: "10%",
              overflow: "scroll",
              backgroundColor: "white",
            }}
          >
            <h5 className="display-8 text-center">HC</h5>
            <button
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: 40,
                height: 40,
                border: 2,
                border: "solid",
                border: "#f5f5f5",
                borderRadius: "50%",
                color: "#ffffff",
                background: "#757575",
                fontWeight: "Bold",
                fondSize: 30,
              }}
              onClick={this.handleClose}
            >
              X
            </button>
            <hr className="my-4"></hr>
            <img
              src="images/Diapositive2.jpeg"
              style={{ width: "100%" }}
              alt=""
            ></img>
            <hr></hr>
            <img
              src="images/Diapositive3.jpeg"
              style={{ width: "100%" }}
              alt=""
            ></img>
          </div>
        </div>
      );
    }

    var myModal2 = "";

    if (this.state.mymodal2 === 1) {
      myModal2 = (
        <div
          onDoubleClick={this.handleClose2}
          style={{
            position: "fixed",
            zIndex: "2147483647",
            display: "block",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            className="jumbotron"
            style={{
              position: "fixed",
              zIndex: "2147483647",
              width: "80%",
              height: "80%",
              top: "10%",
              left: "10%",
              overflow: "scroll",
              backgroundColor: "white",
            }}
          >
            <h5 className="display-8 text-center">ESC</h5>
            <button
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: 40,
                height: 40,
                border: 2,
                border: "solid",
                border: "#f5f5f5",
                borderRadius: "50%",
                color: "#ffffff",
                background: "#757575",
                fontWeight: "Bold",
                fondSize: 30,
              }}
              onClick={this.handleClose2}
            >
              X
            </button>
            <hr className="my-4"></hr>
            <img
              src="images/Diapositive6.jpeg"
              style={{ width: "100%" }}
              alt=""
            ></img>
            <hr></hr>
            <img
              src="images/Diapositive7.jpeg"
              style={{ width: "100%" }}
              alt=""
            ></img>
            <hr></hr>
            <img
              src="images/Diapositive9.jpeg"
              style={{ width: "100%" }}
              alt=""
            ></img>
            <hr></hr>
            <img
              src="images/Diapositive13.jpeg"
              style={{ width: "100%" }}
              alt=""
            ></img>
            <hr></hr>
            <img
              src="images/Diapositive14.jpeg"
              style={{ width: "100%" }}
              alt=""
            ></img>
          </div>
        </div>
      );
    }

    //render for mobile*******************************************************************
    var QN0_sm = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video >= 82 &&
      this.state.video <= 85.99999
    ) {
      QN0_sm = (
        /*
        <div
          className="jumbotron"
          style={{
            position: "fixed",
            zIndex: "2147483637",
            width: "100%",
            height: "100%",
            top: "0%",
            left: "0%",
            overflow: "scroll",
            paddingTop: 2,
          }}
        >
          <h6 className="text-center">Que faites vous ?</h6>
          <ul
            className="list-group"
            style={{ fontSize: 11, fontWeight: "bold" }}
          >
            <li
              style={{ marginBottom: 2 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N1V1")}
            >
              Je complète mon interrogatoire et mon examen clinique
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N1V2")}
            >
              Je réalise des examens paracliniques
            </li>
          </ul>
        </div>*/
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Que faites-vous ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N1V1")}
                    />
                    Je complète mon interrogatoire et mon examen clinique
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N1V2")}
                    />
                    Je réalise des examens paracliniques
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN1_sm = "";
    if (
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N1V1.mp4" &&
        this.state.video >= 67 &&
        this.state.video <= 75.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N1V2.mp4" &&
        this.state.video >= 74 &&
        this.state.video <= 81.99999)
    ) {
      QN1_sm = (
        /*
        <div
          className="jumbotron"
          style={{
            position: "fixed",
            zIndex: "2147483637",
            width: "80%",
            height: "80%",
            top: 0,
            left: "10%",
            overflow: "scroll",
            paddingTop: 2,
          }}
        >
          <h6 className="text-center">
            Quel(s) examen(s) réalisez-vous pour compléter le bilan ?
          </h6>

          <form>
            <ul
              className="list-group"
              style={{ fontSize: 11, fontWeight: "bold" }}
            >
              <li
                className="list-group-item list-group-item-action"
                style={{ marginBottom: 5 }}
              >
                <label>
                  <input
                    name="isCkeck1"
                    type="checkbox"
                    checked={this.state.isCkeck1}
                    onChange={this.handleInputChange}
                    style={{ marginRight: 10, height: 20, width: 20 }}
                  />
                  Échocardiographie transthoracique
                </label>
              </li>
              <li
                className="list-group-item list-group-item-action"
                style={{ marginBottom: 5 }}
              >
                <label>
                  <input
                    name="isCkeck2"
                    type="checkbox"
                    checked={this.state.isCkeck2}
                    onChange={this.handleInputChange}
                    style={{ marginRight: 10, height: 20, width: 20 }}
                  />
                  Écho-Doppler rénal
                </label>
              </li>
              <li
                className="list-group-item list-group-item-action"
                style={{ marginBottom: 5 }}
              >
                <label>
                  <input
                    name="isCkeck3"
                    type="checkbox"
                    checked={this.state.isCkeck3}
                    onChange={this.handleInputChange}
                    style={{ marginRight: 10, height: 20, width: 20 }}
                  />
                  Épreuve d’effort cyclo-ergométrique
                </label>
              </li>
              <li
                className="list-group-item list-group-item-action"
                style={{ marginBottom: 5 }}
              >
                <label>
                  <input
                    name="isCkeck4"
                    type="checkbox"
                    checked={this.state.isCkeck4}
                    onChange={this.handleInputChange}
                    style={{ marginRight: 10, height: 20, width: 20 }}
                  />
                  Bandelette urinaire
                </label>
              </li>
              <li
                className="list-group-item list-group-item-action"
                style={{ marginBottom: 5 }}
              >
                <label>
                  <input
                    name="isCkeck5"
                    type="checkbox"
                    checked={this.state.isCkeck5}
                    onChange={this.handleInputChange}
                    style={{ marginRight: 10, height: 20, width: 20 }}
                  />
                  Examen 5
                </label>
              </li>
            </ul>
          </form>

          <button
            onClick={this.handleNiveau1}
            className="btn btn-primary btn-lg m-2"
          >
            Valider
          </button>
        </div>*/

        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Quel(s) examen(s) réalisez-vous pour compléter le bilan ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <label>
                  <input
                    className="largerCheckbox"
                    name="isCkeck1"
                    type="checkbox"
                    checked={this.state.isCkeck1}
                    onChange={this.handleInputChange}
                  />
                  Échocardiographie transthoracique
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck2"
                    type="checkbox"
                    checked={this.state.isCkeck2}
                    onChange={this.handleInputChange}
                    className="largerCheckbox"
                  />
                  Écho-Doppler rénal
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck3"
                    type="checkbox"
                    checked={this.state.isCkeck3}
                    onChange={this.handleInputChange}
                    // style={{ marginRight: 10, height: 20, width: 20 }}
                    className="largerCheckbox"
                  />
                  Épreuve d’effort cyclo-ergométrique
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck4"
                    type="checkbox"
                    checked={this.state.isCkeck4}
                    onChange={this.handleInputChange}
                    //style={{ marginRight: 10, height: 20, width: 20 }}
                    className="largerCheckbox"
                  />
                  Bandelette urinaire
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck5"
                    type="checkbox"
                    checked={this.state.isCkeck5}
                    onChange={this.handleInputChange}
                    //style={{ marginRight: 10, height: 20, width: 20 }}
                    className="largerCheckbox"
                  />
                  Scintigraphie myocardique de perfusion
                </label>
              </div>
            </form>
            <button
              onClick={this.handleNiveau1}
              className="btn btn-primary btn-lg m-2"
              onMouseDown={this.toggleClass}
            >
              Valider
            </button>
          </div>
        </div>
      );
    }

    var QN2_sm = "";
    if (
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N2V1.mp4" &&
        this.state.video >= 103 &&
        this.state.video <= 107.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N2V2.mp4" &&
        this.state.video >= 102 &&
        this.state.video <= 106.99999)
    ) {
      QN2_sm = (
        /*
        <div
          className="jumbotron"
          style={{
            position: "fixed",
            zIndex: "2147483637",
            width: "80%",
            height: "80%",
            top: 0,
            left: "10%",
            overflow: "scroll",
          }}
        >
          <h6 className="text-center">Que faites vous ?</h6>
          <hr className="my-8"></hr>
          <ul
            className="list-group"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            <li
              style={{ marginBottom: 2 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N3V1")}
            >
              Je prends en charge son HTA et le réévalue dans 3 mois
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N3V2")}
            >
              Je réalise une IRM cardiaque
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N3V3")}
            >
              Je l’adresse à un centre de compétence des cardiomyopathies
            </li>
          </ul>
        </div>*/
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Que faites-vous ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V1")}
                    />
                    Je prends en charge son HTA et le réévalue dans 3 mois
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V2")}
                    />
                    Je réalise une IRM cardiaque
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V3")}
                    />
                    Je l’adresse à un centre de compétence des cardiomyopathies
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN3_1_sm = "";

    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N3V1.mp4" &&
      this.state.video >= 9 &&
      this.state.video <= 11.99999
    ) {
      QN3_1_sm = (
        /*
        <div
          className="jumbotron"
          style={{
            position: "fixed",
            zIndex: "2147483637",
            width: "80%",
            height: "80%",
            top: 0,
            left: "10%",
            overflow: "scroll",
          }}
        >
          <h6 className="text-center">Que faites vous ?</h6>
          <hr className="my-8"></hr>
          <ul
            className="list-group"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N5V3")}
            >
              Je renforce le traitement de son HTA et le réévalue dans 3 mois
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N3V2")}
            >
              Je réalise une IRM cardiaque
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N3V3")}
            >
              Je l’adresse à un centre de compétence des cardiomyopathies
            </li>
          </ul>
        </div>*/
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Que faites-vous ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N5V3")}
                    />
                    Je renforce le traitement de son HTA et le réévalue dans 3
                    mois
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V2")}
                    />
                    Je réalise une IRM cardiaque
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V3")}
                    />
                    Je l’adresse à un centre de compétence des cardiomyopathies
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN3_2_sm = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N3V2.mp4" &&
      this.state.video >= 22 &&
      this.state.video <= 24.99999
    ) {
      QN3_2_sm = (
        /*
        <div
          className="jumbotron"
          style={{
            position: "fixed",
            zIndex: "2147483637",
            width: "80%",
            height: "80%",
            top: 0,
            left: "10%",
            overflow: "scroll",
          }}
        >
          <h6 className="display-8 text-center">Cette IRM….</h6>
          <hr className="my-8"></hr>
          <ul
            className="list-group"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N5V1")}
            >
              Finalise mon bilan d’hypertrophie ventriculaire
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("N3V3")}
            >
              Est incomplète et nécessite d’autres investigations
            </li>
          </ul>
        </div>*/
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Cette IRM…
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N5V1")}
                    />
                    Finalise mon bilan d’hypertrophie ventriculaire
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N5V2")}
                    />
                    Est incomplète et nécessite d’autres investigations
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN4_sm = "";
    if (
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N3V3.mp4" &&
        this.state.video >= 43 &&
        this.state.video <= 48.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N5V1.mp4" &&
        this.state.video >= 43 &&
        this.state.video <= 48.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N5V3.mp4" &&
        this.state.video >= 46 &&
        this.state.video <= 51.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N5V2.mp4" &&
        this.state.video >= 46 &&
        this.state.video <= 51.99999)
    ) {
      QN4_sm = (
        /*
        <div
          className="jumbotron"
          style={{
            position: "fixed",
            zIndex: "2147483637",
            width: "80%",
            height: "80%",
            top: 0,
            left: "10%",
            overflow: "scroll",
          }}
        >
          <h6 className=" text-center">
            Son activité sportive peut expliquer…
          </h6>
          <hr className="my-8"></hr>
          <ul
            className="list-group"
            style={{ fontSize: 12, fontWeight: "bold" }}
          >
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Son hypertension artérielle
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Ses anomalies de repolarisation à l’électrocardiogramme
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Son hypertrophie ventriculaire gauche à l’échocardiographie
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Sa fibrose myocardique à l’IRM
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALGOOD")}
            >
              Aucune réponse des 4
            </li>
          </ul>
        </div>*/
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Son activité sportive peut expliquer…
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Son hypertension artérielle
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Ses anomalies de repolarisation à l’électrocardiogramme
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Son hypertrophie ventriculaire gauche à l’échocardiographie
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Sa fibrose myocardique à l’IRM
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALGOOD")}
                    />
                    Aucune réponse des 4
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    //fin render for mobile*******************************************************************

    //bouton play

    var BoutonPlay = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video < 1 &&
      this.state.statofPlayer === true
    ) {
      BoutonPlay = (
        <div>
          <img
            src="http://videointeractive.henri8.com/Daniel/play.png"
            style={{
              position: "absolute",
              zIndex: "2147483647",
              width: "150px",
              height: "150px",
              top: "40%",
              left: "45%",
            }}
            onClick={this.handlePlay}
            alt=""
          />
        </div>
      );
    } else {
      BoutonPlay = "";
    }

    //bouton play mobile

    var BoutonPlay_sm = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video < 20 &&
      this.state.statofPlayer === true
    ) {
      BoutonPlay_sm = (
        <div>
          <img
            src="http://videointeractive.henri8.com/Daniel/play.png"
            style={{
              position: "absolute",
              zIndex: "2147483647",
              width: "80px",
              height: "80px",
              top: "80px",
              left: "35%",
            }}
            onClick={this.handlePlay}
            alt=""
          />
        </div>
      );
    } else {
      BoutonPlay_sm = "";
    }

    // Bouton HC

    var BoutonHC = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video < 24
    ) {
      BoutonHC = "";
    } else {
      BoutonHC = (
        <div>
          <img
            // src="images/BOUTON-01.png"

            src={
              this.state.hoverbtnHC
                ? "images/BOUTON-01lumi.png"
                : "images/BOUTON-01.png"
            }
            style={{
              position: "fixed",
              top: 40,
              right: 0,
              width: 100,
              height: 100,
              zIndex: "2147483647",
            }}
            onClick={this.handleShowHC}
            onMouseEnter={this.hoverOnHC}
            onMouseLeave={this.hoverOffHC}
            alt=""
          />
        </div>
      );
    }

    // Bouton HC Mobile

    var BoutonHC_sm = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video < 24
    ) {
      BoutonHC_sm = "";
    } else {
      BoutonHC_sm = (
        <div>
          <img
            src="images/BOUTON-01.png"
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              width: 60,
              height: 50,
            }}
            onClick={this.handleShowHC}
            alt=""
          />
        </div>
      );
    }

    // Bouton HSC

    var BoutonHSC = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video < 27
    ) {
      BoutonHSC = "";
    } else {
      BoutonHSC = (
        <div>
          <img
            // src="images/BOUTON-02.png"
            src={
              this.state.hoverbtnHSC
                ? "images/BOUTON-02lumi.png"
                : "images/BOUTON-02.png"
            }
            style={{
              position: "fixed",
              bottom: 80,
              right: 0,
              width: 100,
              height: 100,
              zIndex: "2147483647",
            }}
            onClick={this.handleShowHSC}
            onMouseEnter={this.hoverOnHSC}
            onMouseLeave={this.hoverOffHSC}
            alt=""
          />
        </div>
      );
    }

    // Bouton HSC Mobile

    var BoutonHSC_sm = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video < 27
    ) {
      BoutonHSC_sm = "";
    } else {
      BoutonHSC_sm = (
        <div>
          <img
            src="images/BOUTON-02.png"
            style={{
              position: "relative",
              float: "right",
              top: "-80px",
              width: 60,
              height: 50,
            }}
            onClick={this.handleShowHSC}
            alt=""
          />
        </div>
      );
    }

    //console.log(" Le source actuelle" + this.source);
    var QN0 = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N0V0.mp4" &&
      this.state.video >= 82 &&
      this.state.video <= 85.99999
    ) {
      QN0 = (
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Que faites-vous ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N1V1")}
                    />
                    Je complète mon interrogatoire et mon examen clinique
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N1V2")}
                    />
                    Je réalise des examens paracliniques
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN1 = "";
    if (
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N1V1.mp4" &&
        this.state.video >= 67 &&
        this.state.video <= 75.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N1V2.mp4" &&
        this.state.video >= 74 &&
        this.state.video <= 81.99999)
    ) {
      QN1 = (
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Quel(s) examen(s) réalisez-vous pour compléter le bilan ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <label>
                  <input
                    className="largerCheckbox"
                    name="isCkeck1"
                    type="checkbox"
                    checked={this.state.isCkeck1}
                    onChange={this.handleInputChange}
                  />
                  Échocardiographie transthoracique
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck2"
                    type="checkbox"
                    checked={this.state.isCkeck2}
                    onChange={this.handleInputChange}
                    className="largerCheckbox"
                  />
                  Écho-Doppler rénal
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck3"
                    type="checkbox"
                    checked={this.state.isCkeck3}
                    onChange={this.handleInputChange}
                    // style={{ marginRight: 10, height: 20, width: 20 }}
                    className="largerCheckbox"
                  />
                  Épreuve d’effort cyclo-ergométrique
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck4"
                    type="checkbox"
                    checked={this.state.isCkeck4}
                    onChange={this.handleInputChange}
                    //style={{ marginRight: 10, height: 20, width: 20 }}
                    className="largerCheckbox"
                  />
                  Bandelette urinaire
                </label>
              </div>

              <div style={margBott}>
                <label>
                  <input
                    name="isCkeck5"
                    type="checkbox"
                    checked={this.state.isCkeck5}
                    onChange={this.handleInputChange}
                    //style={{ marginRight: 10, height: 20, width: 20 }}
                    className="largerCheckbox"
                  />
                  Scintigraphie myocardique de perfusion
                </label>
              </div>
            </form>
            <button
              onClick={this.handleNiveau1}
              className="btn btn-primary btn-lg m-2"
              onMouseDown={this.toggleClass}
            >
              Valider
            </button>
          </div>
        </div>
      );
    }

    var QN2 = "";
    if (
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N2V1.mp4" &&
        this.state.video >= 103 &&
        this.state.video <= 107.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N2V2.mp4" &&
        this.state.video >= 102 &&
        this.state.video <= 106.99999)
    ) {
      QN2 = (
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Que faites-vous ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V1")}
                    />
                    Je prends en charge son HTA et le réévalue dans 3 mois
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V2")}
                    />
                    Je réalise une IRM cardiaque
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V3")}
                    />
                    Je l’adresse à un centre de compétence des cardiomyopathies
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN3_1 = "";

    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N3V1.mp4" &&
      this.state.video >= 9 &&
      this.state.video <= 11.99999
    ) {
      QN3_1 = (
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Que faites-vous ?
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N5V3")}
                    />
                    Je renforce le traitement de son HTA et le réévalue dans 3
                    mois
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V2")}
                    />
                    Je réalise une IRM cardiaque
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N3V3")}
                    />
                    Je l’adresse à un centre de compétence des cardiomyopathies
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN3_2 = "";
    if (
      this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N3V2.mp4" &&
      this.state.video >= 22 &&
      this.state.video <= 24.99999
    ) {
      QN3_2 = (
        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Cette IRM…
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N5V1")}
                    />
                    Finalise mon bilan d’hypertrophie ventriculaire
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("N5V2")}
                    />
                    Est incomplète et nécessite d’autres investigations
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    var QN4 = "";
    if (
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N3V3.mp4" &&
        this.state.video >= 43 &&
        this.state.video <= 48.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N5V1.mp4" &&
        this.state.video >= 43 &&
        this.state.video <= 48.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N5V3.mp4" &&
        this.state.video >= 46 &&
        this.state.video <= 51.99999) ||
      (this.state.currentSrc ===
        "http://videointeractive.henri8.com/VIDEOSIVI/N5V2.mp4" &&
        this.state.video >= 46 &&
        this.state.video <= 51.99999)
    ) {
      QN4 = (
        /*
        <div
          className="jumbotron"
          style={{
            position: "fixed",
            zIndex: "2147483637",
            width: "100%",
            height: "100%",
            top: 0,
            borderRadius: "30px"
          }}
        >
          <h1 className="display-8 text-center">
            Son activité sportive peut expliquer…
          </h1>
          <hr className="my-4"></hr>
          <ul
            className="list-group"
            style={{ fontSize: 30, fontWeight: "bold" }}
          >
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Son hypertension artérielle
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Ses anomalies de repolarisation à l’électrocardiogramme
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Son hypertrophie ventriculaire gauche à l’échocardiographie
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALBAD")}
            >
              Sa fibrose myocardique à l’IRM
            </li>
            <li
              style={{ marginBottom: 5 }}
              className="list-group-item list-group-item-action"
              onClick={this.changeSource("FINALGOOD")}
            >
              Aucune réponse des 4
            </li>
          </ul>
        </div>

        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={ImageFond}
            alt=""
          />
          <h3
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              fontSize: 46,
              color: "#5e2f80",
            }}
          >
            Son activité sportive peut expliquer…
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <div style={margBott} onClick={this.changeSource("FINALBAD")}>
              <img
                src="images/FlecheQuestionStory-05.png"
                style={{ width: 60 }}
                alt=""
              />

              <span style={SpanLef}>Son hypertension artérielle</span>
            </div>

            <div style={margBott} onClick={this.changeSource("FINALBAD")}>
              <img
                src="images/FlecheQuestionStory-05.png"
                style={{ width: 60 }}
                alt=""
              />

              <span style={SpanLef}>
                Ses anomalies de repolarisation à l’électrocardiogramme
              </span>
            </div>

            <div style={margBott} onClick={this.changeSource("FINALBAD")}>
              <img
                src="images/FlecheQuestionStory-05.png"
                style={{ width: 60 }}
                alt=""
              />

              <span style={SpanLef}>
                Son hypertrophie ventriculaire gauche à l’échocardiographie
              </span>
            </div>

            <div style={margBott} onClick={this.changeSource("FINALBAD")}>
              <img
                src="images/FlecheQuestionStory-05.png"
                style={{ width: 60 }}
                alt=""
              />

              <span style={SpanLef}>Sa fibrose myocardique à l’IRM</span>
            </div>

            <div style={margBott} onClick={this.changeSource("FINALGOOD")}>
              <img
                src="images/FlecheQuestionStory-05.png"
                style={{ width: 60 }}
                alt=""
              />

              <span style={SpanLef}>Aucune réponse des 4</span>
            </div>
          </div>
        </div> */

        <div>
          <img
            src="images/FONDquestion_Story-04.png"
            style={{
              position: "fixed",
              top: "3%",
              left: "5%",
              height: "90%",
              width: "90%",
              opacity: 1,
            }}
            alt=""
          />
          <h3
            className="FondSizeQuestionTitle"
            style={{
              position: "fixed",
              left: "10.5%",
              top: "9.6%",
              color: "#5e2f80",
            }}
          >
            Son activité sportive peut expliquer…
          </h3>

          <div
            className="FondSizeQuestion"
            style={{
              position: "fixed",
              top: "22%",
              left: "10%",
              color: "#5e2f80",
            }}
          >
            <form>
              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Son hypertension artérielle
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Ses anomalies de repolarisation à l’électrocardiogramme
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Son hypertrophie ventriculaire gauche à l’échocardiographie
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALBAD")}
                    />
                    Sa fibrose myocardique à l’IRM
                  </label>
                </span>
              </div>

              <div style={margBott}>
                <span>
                  <label>
                    <input
                      className="largerCheckbox"
                      name="isCkeck1"
                      type="checkbox"
                      onClick={this.changeSource("FINALGOOD")}
                    />
                    Aucune réponse des 4
                  </label>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Media queries={{ small: "(max-width: 799px)" }}>
          {(matches) =>
            matches.small ? (
              <DeviceOrientation lockOrientation={"landscape"}>
                {/* Will only be in DOM in landscape */}
                <Orientation orientation="landscape" alwaysRender={true}>
                  <div className="h1">
                    <div style={{ zIndex: "1", position: "aboslute" }}>
                      <Player
                        ref={(player) => {
                          this.player = player;
                        }}
                        autoPlay
                        playsInline
                      >
                        <source src={this.state.source} />
                        <ControlBar
                          autoHide={false}
                          disableDefaultControls={false}
                          className="my-class"
                        />
                      </Player>
                    </div>
                    {QN0_sm}
                    {QN1_sm}
                    {QN2_sm}
                    {QN3_1_sm}
                    {QN3_2_sm}
                    {QN4_sm}
                    {BoutonHC_sm}
                    {myModal}
                    {myModal2}
                    {BoutonHSC_sm}
                    {BoutonPlay_sm}
                  </div>
                </Orientation>
                {/* Will stay in DOM, but is only visible in portrait */}
                <Orientation orientation="portrait" alwaysRender={false}>
                  <div className="h2">
                    <p
                      style={{
                        fontSize: 12,
                        position: "fixed",
                        bottom: "50%",
                        textAlign: "center",
                      }}
                    ></p>
                  </div>
                </Orientation>
              </DeviceOrientation>
            ) : (
              <div>
                <div className={this.getOs() === "Mac" ? "player-wrapper" : ""}>
                  <Player
                    ref={(player) => {
                      this.player = player;
                    }}
                    autoPlay
                  >
                    <source src={this.state.source} />
                    <ControlBar
                      autoHide={false}
                      disableDefaultControls={false}
                      className="my-class"
                    />
                  </Player>
                  {BoutonHSC}
                </div>
                {QN0}
                {QN1}
                {QN2}
                {QN3_1}
                {QN3_2}
                {QN4}
                {BoutonHC}
                {myModal}
                {myModal2}
                {BoutonPlay}
              </div>
            )
          }
        </Media>
      </div>
    );
  }
}

export default Video;
