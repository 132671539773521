import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "video-react/dist/video-react.css"; // import css

//import PlayerControlExample from "./componenets/playerControlExample";
import Video from "./componenets/video";
//import PlayerControlExample from "./componenets/playerControlExample";
ReactDOM.render(<Video />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
